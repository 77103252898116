import React, { useState, useEffect } from "react";
import {
  getNotes,
  getBookCover,
  getBookAuthor,
  updateNoteById,
  deleteNoteById,
  emailNotes,
} from "../services/apiService";
import NoteList from "./NoteList";
import NoteForm from "./NoteForm";
import BookSelection from "./BookSelection";
import { useCookies } from "react-cookie";
import exportNotesToFile from "./Export";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUserFriends,
  faDownload,
  faEnvelope,
  faPaperPlane,
  faTrash,
  faToggleOn,
  faToggleOff,
} from "@fortawesome/free-solid-svg-icons";
import { removeBook } from "../services/apiService";

function NoteTakingPage() {
  const [cookies] = useCookies(null);
  const [selectedBook, setSelectedBook] = useState(null);
  const [notes, setNotes] = useState([]);
  const [email] = useState(cookies.Email);
  const [name] = useState(cookies.Name);
  const [bookCover, setBookCover] = useState(null);
  const [bookAuthor, setBookAuthor] = useState(null);
  const [editedText, setEditedText] = useState("");
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [emailingNotes, setEmailingNotes] = useState(null);
  const [alternateEmail, setAlternateEmail] = useState("");
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [showFriendEmailField, setShowFriendEmailField] = useState(false);
  const [rapidLogging, setRapidLogging] = useState(false);

  useEffect(() => {
    // Get the last selected book from localStorage
    const lastSelectedBook = localStorage.getItem("lastSelectedBook");
    const rapidLogPreference = localStorage.getItem("rapidLogPreference");
    // If lastSelectedBook exists, set it as the initial selectedBook
    if (lastSelectedBook) {
      setSelectedBook(JSON.parse(lastSelectedBook));
    }
    const parsedRapidLogPreference = rapidLogPreference === "true";

    if (rapidLogPreference !== null) {
      setRapidLogging(parsedRapidLogPreference);
    } else {
      setRapidLogging(false);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedBook) {
        try {
          // Fetch book cover
          const cover = await getBookCover(selectedBook.book_isbn);
          const author = await getBookAuthor(selectedBook.book_isbn, email);

          setBookCover(cover);
          setBookAuthor(author);

          // Fetch notes as before
          const notesData = await getNotes(selectedBook.book_isbn, email);

          if (notesData) {
            setNotes(notesData);
          } else {
            console.error("Notes data is empty or undefined.");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [selectedBook, email]);

  const handleBookSelect = (book) => {
    // Set the selected book and save it to localStorage
    setSelectedBook(book);
    localStorage.setItem("lastSelectedBook", JSON.stringify(book));
  };

  const onSaveNote = (newNote) => {
    // Handle the newly saved note, e.g., by adding it to the list of notes.
    // You can update the list of notes here:
    //console.log('New note to be saved:', newNote);
    setNotes([...notes, newNote]);
  };

  const handleEditNote = async (noteId, editedText) => {
    try {
      //console.log('Edit button clicked for notes_id:', noteId);
      //console.log('New text:', editedText);

      if (editedText.trim() !== "") {
        // Save the edited note if it's not empty
        const updatedNote = await updateNoteById(noteId, editedText, email);
        console.log("Updated Note:", updatedNote);

        // Update the state with the edited note
        setNotes((prevNotes) => {
          //console.log('Previous Notes:', prevNotes);
          //console.log('Updated Note:', updatedNote);
          return prevNotes.map((note) =>
            note.notes_id === noteId ? updatedNote : note
          );
        });
      }

      // Clear the edited note state
      setEditedText("");
      setEditingNoteId(null);
    } catch (error) {
      console.error("Error updating note:", error);
    }
  };

  const handleDeleteNote = async (noteId) => {
    // Display a confirmation prompt for deletion
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this note?"
    );

    if (isConfirmed) {
      try {
        // Implement the logic for deleting a note
        await deleteNoteById(noteId, email);
        console.log("Note deleted successfully");

        // Fetch updated notes after deletion if needed
        setNotes((prevNotes) =>
          prevNotes.filter((note) => note.notes_id !== noteId)
        );
      } catch (error) {
        console.error("Error deleting note:", error);
      }
    }
  };

  const handleRapidLog = () => {
    setRapidLogging(!rapidLogging);
    localStorage.setItem("rapidLogPreference", String(!rapidLogging));
  };

  const handleEditClick = (noteId) => {
    // Set the noteId to indicate that editing is in progress
    setEditingNoteId(noteId);
    // Set the edited text to the current text of the note
    const noteToEdit = notes.find((note) => note.notes_id === noteId);
    setEditedText(noteToEdit ? noteToEdit.notes : "");
  };

  const handleEditCancel = () => {
    // Clear the edited text state and reset editingNoteId
    setEditedText("");
    setEditingNoteId(null);
  };

  const handleExportNotes = async () => {
    try {
      const author = await getBookAuthor(selectedBook.book_isbn);
      await exportNotesToFile(
        selectedBook.book_isbn,
        email,
        selectedBook.title,
        author,
        name
      );
    } catch (error) {
      console.error("Error exporting notes:", error);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailNotes = async () => {
    setEmailingNotes((prevValue) =>
      prevValue === null ? selectedBook.book_isbn : null
    );
    if (emailingNotes == null) {
      setShowFriendEmailField(false);
    }
  };

  const handleSendToFriend = () => {
    setShowFriendEmailField((prevValue) =>
      prevValue === false ? true : false
    );
  };

  const handleEmailToMyself = async () => {
    try {
      const author = await getBookAuthor(selectedBook.book_isbn);
      await emailNotes(
        selectedBook.book_isbn,
        selectedBook.title,
        author,
        email
      );

      setMessage(`Notes have been sent to ${email}. Please check your inbox.`);
      setTimeout(() => setMessage(""), 7000);
      setEmailingNotes(null);
    } catch (error) {
      console.error("Error sending email to myself:", error);
      setError("There seems to be a problem sending notes to your email.");
      setTimeout(() => setError(""), 7000);
    }
  };

  const handleSubmitFriendEmail = async () => {
    try {
      if (alternateEmail === null || !validateEmail(alternateEmail)) {
        setError("Please enter a valid email address.");
        setTimeout(() => setError(""), 5000);
        return;
      }

      const author = await getBookAuthor(selectedBook.book_isbn);
      await emailNotes(
        selectedBook.book_isbn,
        selectedBook.title,
        author,
        email,
        alternateEmail
      );

      setMessage(`Notes have been sent to ${alternateEmail}.`);
      setTimeout(() => setMessage(""), 7000);

      setEmailingNotes(null);
      setShowFriendEmailField(false);
    } catch (error) {
      console.error("Error sending email to friend:", error);
      setError("There seems to be a problem sending notes to your friend.");
      setTimeout(() => setError(""), 7000);
    }
  };

  const handleDeleteBook = async (selectedBook) => {
    const isConfirmed = window.confirm(
      `Are you sure you want to delete ${selectedBook.title}? You'll lose all your notes as well.`
    );

    if (isConfirmed) {
      try {
        const response = await removeBook(selectedBook.book_isbn, email);

        if (response && response.status) {
          if (response.status === 200 && response.data.success) {
            // Handle success if needed
            localStorage.removeItem("lastSelectedBook");
            localStorage.setItem("selectedOption", "Book Search");
            window.location.reload();
          } else {
            console.error(
              `Error deleting book. Server response: ${JSON.stringify(
                response.data
              )}`
            );
          }
        } else {
          console.error(
            "Error deleting book. Server response is undefined or null."
          );
        }
      } catch (error) {
        console.error("Error deleting book:", error.message);
      }
    }
  };

  return (
    <div className="module-containers">
      <BookSelection onBookSelect={handleBookSelect} />
      <br></br>
      {selectedBook && (
        <div>
          <h4>{selectedBook.title}</h4>
          <h5>{bookAuthor}</h5>

          {bookCover && (
            <img
              src={bookCover}
              alt="Book Cover"
              className="img-fluid mb-3"
              loading="lazy"
            />
          )}

          <div className="d-flex align-items-center mt-2">
            <button
              style={{ marginRight: "1.25rem" }}
              className="btn btn-light"
              onClick={handleExportNotes}
            >
              <FontAwesomeIcon icon={faDownload} /> Export Notes
            </button>

            <button
              style={{ marginRight: "1.25rem" }}
              className="btn btn-light"
              onClick={handleEmailNotes}
            >
              <FontAwesomeIcon icon={faEnvelope} /> Email Notes
            </button>

            <button
              className="btn btn-light"
              onClick={() => handleDeleteBook(selectedBook)}
            >
              <FontAwesomeIcon icon={faTrash} /> Delete Book
            </button>

            <div className="toggle-container" style={{ paddingLeft: "20px" }}>
              <div className="form-check form-switch me-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="rapidLoggingSwitch"
                  checked={rapidLogging}
                  onChange={handleRapidLog}
                />
                <label
                  className="form-check-label"
                  htmlFor="rapidLoggingSwitch"
                >
                  Return Saves Notes
                </label>
              </div>
            </div>
          </div>

          {emailingNotes && (
            <div>
              {/* Email to Myself Button */}
              <div className="mt-3">
                <button
                  className="btn btn-primary mr-2"
                  onClick={handleEmailToMyself}
                >
                  <FontAwesomeIcon icon={faUser} /> Send to Myself
                </button>
              </div>

              {/* Spacing between the two sections */}
              <div className="mt-2">
                {/* Email to Friend Button */}
                <button
                  className="btn btn-success mr-2 mt-3"
                  onClick={handleSendToFriend}
                >
                  <FontAwesomeIcon icon={faUserFriends} /> Send to a Friend
                </button>

                {/* Conditionally rendered Friend Email Field */}
                {showFriendEmailField && (
                  <div className="mt-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter a friend's email (you'll be on cc)"
                      onChange={(e) => setAlternateEmail(e.target.value)}
                    />
                  </div>
                )}

                {/* Submit Friend's Email Button */}
                {showFriendEmailField && (
                  <button
                    className="btn btn-primary mt-3"
                    onClick={handleSubmitFriendEmail}
                  >
                    <FontAwesomeIcon icon={faPaperPlane} /> Send
                  </button>
                )}
              </div>
            </div>
          )}

          {error && <p className="text-danger">{error}</p>}
          {message && (
            <div className="text-success">
              <p>{message}</p>
            </div>
          )}

          <NoteForm onSaveNote={onSaveNote} selectedBook={selectedBook} />
          <NoteList
            notes={notes}
            onEdit={handleEditNote}
            onDelete={handleDeleteNote}
            onEditClick={handleEditClick}
            onEditCancel={handleEditCancel}
            editedText={editedText}
            editing={editingNoteId}
          />
        </div>
      )}
    </div>
  );
}

export default NoteTakingPage;
