import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveNoteToDatabase } from "../services/apiService";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import MarkdownIt from "markdown-it";

function NoteForm({ onSaveNote, selectedBook }) {
  const [newNote, setNewNote] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(null);
  const [email] = useState(cookies.Email);
  const md = new MarkdownIt();

  const handleNoteChange = (e) => {
    setNewNote(e.target.value);
  };

  const handleSaveNote = () => {
    if (newNote.trim() !== "") {
      saveNoteToDatabase(selectedBook.book_isbn, newNote, email)
        .then((response) => {
          console.log("Note saved to the database:", response);
          onSaveNote(response); // Pass the response object to onSaveNote
          setNewNote("");
          document.getElementById("rendered-markdown").innerHTML = ""; // Clear the rendered Markdown content
        })
        .catch((error) => console.error("Error saving note:", error));
    } else {
      console.error("Error: newNote is null or empty");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // If Enter key is pressed, call handleSaveNote
      handleSaveNote();
    }
  };

  useEffect(() => {
    const textarea = document.getElementById("log");
    if (textarea) {
      textarea.addEventListener("input", function (event) {
        const markdownContent = event.target.value;
        const htmlContent = md.render(markdownContent);
        document.getElementById("rendered-markdown").innerHTML = htmlContent;
      });
    }
  }, []);

  return (
    <div className="my-4">
      <textarea
        id="log"
        rows="4"
        type="text"
        className="form-control notes-box"
        placeholder="Log your notes here"
        value={newNote}
        onChange={handleNoteChange}
        {...(localStorage.getItem("rapidLogPreference") === "true"
          ? { onKeyPress: handleKeyPress }
          : {})}
      />
      <button className="btn btn-light mt-2" onClick={handleSaveNote}>
        <FontAwesomeIcon icon={faSave} /> Save
      </button>
      <div id="rendered-markdown"></div>
    </div>
  );
}

export default NoteForm;
