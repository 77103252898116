import React, { useEffect, useState } from "react";
import { getBookCover } from "../services/apiService";
import { Grid2, Box, Typography, useColorScheme } from "@mui/material";
import { BookItem, Thumbnail, BookTitle, BookAuthor } from "../styles";

const DEFAULT_COVER_URL = "https://picsum.photos/128/192";
const baseURL =
  process.env.REACT_APP_ENV === "development"
    ? `${process.env.REACT_APP_SERVER_URL_DEV}/api`
    : "/api";

const BookGrid = ({ books }) => {
  const [coverUrls, setCoverUrls] = useState([]);
  useEffect(() => {
    if (!books || books.length === 0) return;
    const fetchImages = async () => {
      const promises = books.map((book) => getBookCover(book.book_isbn));
      const urls = await Promise.all(promises);
      setCoverUrls(urls);
    };
    fetchImages().catch((error) =>
      console.error("Error fetching cover images:", error)
    );
  }, [books]);

  const handleTileClick = (bookISBN) => {
    const selectedBook = books.find((book) => book.book_isbn === bookISBN);
    localStorage.setItem("lastSelectedBook", JSON.stringify(selectedBook));
    localStorage.setItem("selectedOption", "Notes");
    window.location.reload();
  };

  return (
    <Grid2 container spacing={10} justifyContent="center">
      {books && books.length > 0 ? (
        books.map((book, index) => (
          <Grid2 item xs={12} sm={6} md={3} key={book.book_isbn}>
            <BookItem onClick={() => handleTileClick(book.book_isbn)}>
              <Thumbnail
                src={coverUrls[index] || DEFAULT_COVER_URL}
                alt={book.title}
                loading="lazy"
                onError={() =>
                  console.error(`Error loading thumbnail for ${book.title}`)
                }
              />
              <BookTitle variant="h6">{book.title}</BookTitle>
              <BookAuthor variant="body2">{book.author}</BookAuthor>{" "}
            </BookItem>
          </Grid2>
        ))
      ) : (
        <Grid2 item xs={12}>
          <Box textAlign="center">
            <Typography variant="body1">
              Your library is empty - search for books to add.
            </Typography>
          </Box>
        </Grid2>
      )}
    </Grid2>
  );
};

export default BookGrid;
