import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountUp, faSortAmountDown, faEdit, faTrash, faSave, faUndo } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';

function NoteList({ notes, onEdit, onDelete, onEditClick, onEditCancel }) {
  const [reverseOrder, setReverseOrder] = useState(false);

  useEffect(() => {
    const sortOrderPreference = localStorage.getItem('reverseOrder');
    const parsedSortOrderPreference = sortOrderPreference === 'true';

    if (sortOrderPreference !== null) {
      setReverseOrder(parsedSortOrderPreference);
    } else {
      setReverseOrder(false);
    }
  }, []);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const toggleSortOrder = () => {
    setReverseOrder(!reverseOrder);
    localStorage.setItem('reverseOrder', String(!reverseOrder));
  };

  const sortedNotes = notes.slice().sort((a, b) => {
    const dateTimeA = new Date(a.date);
    const dateTimeB = new Date(b.date);

    if (dateTimeA > dateTimeB) {
      return reverseOrder ? -1 : 1;
    } else if (dateTimeA < dateTimeB) {
      return reverseOrder ? 1 : -1;
    }

    return 0;
  });

  const [editingNoteId, setEditingNoteId] = useState(null);
  const [localEditedText, setLocalEditedText] = useState('');

  const handleEditClick = (noteId) => {
    setEditingNoteId(noteId);
    const noteToEdit = notes.find((note) => note.notes_id === noteId);
    setLocalEditedText(noteToEdit ? noteToEdit.notes : '');
    onEditClick(noteId);
  };

  const handleEditCancel = () => {
    setEditingNoteId(null);
    setLocalEditedText('');
    onEditCancel();
  };

  const handleEditSave = (noteId) => {
    onEdit(noteId, localEditedText);
    setEditingNoteId(null);
  };

  return (
    <div className="my-4">
      <button onClick={toggleSortOrder} className='btn btn-light mt-2 mb-3'>
        {reverseOrder ? (
          <>
            <FontAwesomeIcon icon={faSortAmountDown} /> Sort Earliest Notes on Top
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faSortAmountUp} /> Sort Latest Notes on Top
          </>
        )}
      </button>

      {sortedNotes && sortedNotes.length > 0 ? (
        sortedNotes.map((note) => (
          <div key={`${note.notes_id}-${note.reader_id}`} className="mb-3">
            <p className="mb-1" style={{ whiteSpace: 'pre-line' }}>
              {note.date ? formatDate(note.date) : 'Date not available'}: 
              <ReactMarkdown>{note.notes}</ReactMarkdown>
            </p>
            {editingNoteId === note.notes_id ? (
              <div className="d-flex">
                <textarea
                  type="text"
                  value={localEditedText}
                  onChange={(e) => setLocalEditedText(e.target.value)}
                  className="form-control notes-box"
                  placeholder="Edit your note"
                  style={{ width: '80%', resize: 'none' }}
                />
                <div>
                  <button className="btn btn-success ms-2" onClick={() => handleEditSave(note.notes_id)}>
                    <FontAwesomeIcon icon={faSave} />
                  </button>
                  <button className="btn btn-secondary ms-2" onClick={handleEditCancel}>
                    <FontAwesomeIcon icon={faUndo} />
                  </button>
                </div>
              </div>
            ) : (
              <div className="d-flex">
                <button className="btn me-2" onClick={() => handleEditClick(note.notes_id)}>
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button className="btn" onClick={() => onDelete(note.notes_id)}>
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            )}
          </div>
        ))
      ) : (
        <p>No notes available.</p>
      )}
    </div>
  );
}

export default NoteList;
