import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  IconButton,
  Container,
  Stack,
  useMediaQuery,
} from "@mui/material";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";

const baseURL =
  process.env.REACT_APP_ENV === "development"
    ? `${process.env.REACT_APP_SERVER_URL_DEV}/api`
    : "/api";

function Auth() {
  const [cookies, setCookie, removeCookie] = useCookies(null);
  const [isLogIn, setIsLogin] = useState(true);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [name, setName] = useState(null); // Add state for the user's name
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [isForgotPassword, setIsForgotPassword] = useState(null);

  const viewLogin = (status) => {
    setError(null);
    setIsLogin(status);
  };

  const handleForgotPassword = () => {
    if (isForgotPassword) {
      setIsForgotPassword(false);
    } else {
      setIsForgotPassword(true);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const isValidEmail = validateEmail(email);

    if (!isValidEmail) {
      setError("Please enter a valid email address.");
      setTimeout(() => setError(""), 5000);
    } else {
      try {
        setError("");

        const response = await fetch(`${baseURL}/auth/request-password-reset`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email }),
        });
        if (response.status === 200) {
          setMessage(
            `A password reset link has been sent to ${email}. Please check your inbox.`
          );
        } else if (response.status === 500) {
          setError("An unexpected error occurred. Please email Claude.");
        } else if (response.status === 404) {
          setError("An account with this email doesn't exist.");
        } else {
          // Handle other error cases
          console.error("Unexpected error:", response.statusText);
          setError("An unexpected error occurred. Please email Claude.");
        }

        setTimeout(() => {
          setMessage("");
          setIsForgotPassword(false); // Redirect to login page after 5 seconds
        }, 5000);
      } catch (error) {
        // Handle unexpected errors
        console.error("Unexpected error:", error);
        setError("An unexpected error occurred. Please try again later.");
      }
    }
  };

  const handleSignUp = () => {
    // Combine setIsForgotPassword(false) and () => viewLogin(false)
    setIsForgotPassword(false);
    viewLogin(false);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    if (password == null) {
      return "Password is required.";
    }

    // Define password requirements
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password);

    // Check each requirement
    if (!(password.length >= 8 && password.length <= 24)) {
      return "Password must be between 8 -24 characters long.";
    }

    if (!hasDigit) {
      return "Password must contain at least one digit.";
    }

    if (!hasSpecialChar) {
      return "Password must contain at least one special character.";
    }

    // If all requirements are met, the password is valid
    return null;
  };

  const handleSubmit = async (e, endpoint) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      setTimeout(() => setError(""), 5000);
      return;
    }

    const validationError = validatePassword(password);

    if (validationError) {
      setError(validationError);
      setTimeout(() => setError(""), 5000);
      return;
    }

    if (!isLogIn && !name) {
      setError("Please fill in all required fields.");
      setTimeout(() => setError(""), 5000);
      return;
    }

    if (!isLogIn && !password) {
      setError("Please fill in all required fields.");
      setTimeout(() => setError(""), 5000);
      return;
    }

    if (!isLogIn && password !== confirmPassword) {
      setError("Make sure passwords match!");
      setTimeout(() => setError(""), 5000);
      return;
    }

    // Include the user's name in the request body only when signing up
    const requestBody = isLogIn
      ? { email, password }
      : { email, password, name };

    try {
      const response = await fetch(`${baseURL}/auth/${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (response.ok) {
        // Set cookies only if the response contains a valid token
        if (data.token) {
          setCookie("AuthToken", data.token, { secure: true });
          setCookie("Email", data.email, { secure: true });
          setCookie("Name", data.name, { secure: true });
        } else {
          setError("Incorrect email or password. Please try again.");
          setTimeout(() => setError(""), 5000);
          removeCookie("AuthToken");
          removeCookie("Email");
          removeCookie("Name");
          // Delay the reload for 3 seconds
          // setTimeout(() => window.location.reload(), 3000);
        }
      } else {
        console.log("Error Response:", response);
        if (response.status === 401) {
          setError("Incorrect email or password. Please try again.");
          setTimeout(() => setError(""), 5000);
        } else {
          setError(data.detail || "An error occurred!");
          setTimeout(() => setError(""), 5000);
        }
        removeCookie("AuthToken");
        removeCookie("Email");
        removeCookie("Name");
        //setTimeout(() => window.location.reload(), 5000);
      }
    } catch (error) {
      console.error("Error during fetch:", error);
      setError("An unexpected error occurred.");
      setTimeout(() => setError(""), 5000);
      removeCookie("AuthToken");
      removeCookie("Email");
      removeCookie("Name");
      //setTimeout(() => window.location.reload(), 5000);
    }
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Container maxWidth="lg" sx={{ padding: 4 }}>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={isMobile ? 4 : 2}>
        {/* Left side: App Details */}
        <Box
          sx={{
            flex: 1,
            padding: 2,
            backgroundColor: isMobile ? "#ffffff" : "#f5f5f5",
            borderRadius: isMobile ? 0 : 2,
            boxShadow: isMobile ? 0 : 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            order: isMobile ? 2 : 1,
            marginTop: isMobile ? 4 : 0,
          }}
        >
          {isMobile ? (
            <img
              src="library_mobile.jpeg"
              alt="Mobile view"
              style={{
                maxWidth: "100%",
                borderRadius: isMobile ? 0 : 8,
                border: isMobile ? "0.5px solid" : "0.5px",
              }}
            />
          ) : (
            <img
              src="library.jpeg"
              alt="PC view"
              style={{ maxWidth: "100%", borderRadius: 8 }}
            />
          )}
        </Box>

        {/* Right side: Auth Form */}
        <Box
          sx={{
            flex: 1,
            padding: 2,
            border: "1px solid #ddd",
            borderRadius: isMobile ? 0 : 2,
            boxShadow: isMobile ? 0 : 2,
            order: isMobile ? 1 : 2,
            marginBottom: isMobile ? 4 : 0,
          }}
        >
          <form>
            <Box display="flex" alignItems="center">
              <BookOutlinedIcon />
              <Typography variant="h5" component="div" sx={{ marginLeft: 1 }}>
                LitLogs
              </Typography>
            </Box>

            <br></br>
            <Typography variant="body1">
              Capturing those 'aha' moments, favorite quotes, and reflections on
              the books you read.
            </Typography>

            <br></br>
            <Typography variant="h6">
              {isForgotPassword
                ? "Password Reset"
                : isLogIn
                ? "Log In"
                : "Sign Up"}
            </Typography>
            <br />

            {!isForgotPassword && !isLogIn && (
              <TextField
                fullWidth
                label="First Name"
                variant="outlined"
                margin="normal"
                onChange={(e) => setName(e.target.value)}
              />
            )}

            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              margin="normal"
              onChange={(e) => setEmail(e.target.value)}
            />
            {!isLogIn && email && (
              <div style={{ marginLeft: "10px" }}>
                Valid Email:{" "}
                {!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) ? (
                  <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
                ) : (
                  <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
                )}
              </div>
            )}

            {!isLogIn && email && <br />}

            {!isForgotPassword && (
              <TextField
                fullWidth
                label="Password"
                type="password"
                variant="outlined"
                margin="normal"
                onChange={(e) => setPassword(e.target.value)}
              />
            )}

            {/* Password Validation Indicators */}
            {!isLogIn && password && (
              <>
                <div style={{ marginLeft: "10px" }}>
                  8-24 Characters Long:{" "}
                  {!(password.length >= 8 && password.length <= 24) ? (
                    <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  )}
                </div>
                <div style={{ marginLeft: "10px" }}>
                  Has Number:{" "}
                  {!/\d/.test(password) ? (
                    <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  )}
                </div>
                <div style={{ marginLeft: "10px" }}>
                  Has Special Character:{" "}
                  {!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password) ? (
                    <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  )}
                </div>
              </>
            )}

            {!isForgotPassword && !isLogIn && (
              <TextField
                fullWidth
                label="Confirm Password"
                type="password"
                variant="outlined"
                margin="normal"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            )}

            {!isLogIn && password && confirmPassword && (
              <div style={{ marginLeft: "10px" }}>
                Passwords Match:{" "}
                {!(password === confirmPassword) ? (
                  <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
                ) : (
                  <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
                )}
              </div>
            )}

            <Stack direction="row" spacing={2} sx={{ marginTop: 2 }}>
              {isForgotPassword ? (
                <>
                  <Button variant="text" onClick={handleForgotPassword}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleResetPassword}
                  >
                    Reset Password
                  </Button>
                </>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={(e) => handleSubmit(e, isLogIn ? "login" : "signup")}
                >
                  {isLogIn ? "Log In" : "Sign Up"}
                </Button>
              )}
            </Stack>

            {!isForgotPassword && isLogIn && (
              <div>
                <Button variant="text" onClick={handleForgotPassword}>
                  Forgot Password?
                </Button>
              </div>
            )}

            {error && <Typography color="error">{error}</Typography>}
            {message && <Typography color="success.main">{message}</Typography>}
          </form>

          <Stack direction="row" spacing={2} sx={{ marginTop: 2 }}>
            <Button
              variant="outlined"
              onClick={handleSignUp}
              color={isLogIn ? "default" : "primary"}
            >
              Sign Up
            </Button>
            <Button
              variant="outlined"
              onClick={() => viewLogin(true)}
              color={isLogIn ? "primary" : "default"}
            >
              Log In
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}

export default Auth;
