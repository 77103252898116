import React from "react";
import { useCookies } from "react-cookie";

function About() {
  const [cookies] = useCookies(null);
  const userName = cookies.Name;

  return (
    <div>
      <br></br>
      <h3>Welcome to LitLogs, {userName}</h3>
      <p>
        Your companion for capturing those 'aha' moments, favorite quotes, and
        spontaneous reflections on the books you read.
      </p>
      <p>
        Search for a book, view a snapshot of its details, and add it to your
        Library. Then jot down brief notes in the Log.
      </p>
      <p>
        Each note is accompanied by a timestamp, creating a chronological log
        that mirrors your reading journey.
      </p>

      <p>
        I'd love to hear from you. Any questions or suggestions? Email{" "}
        <a href="mailto:claude@litlogs.com?subject=Feedback%20on%20Litlogs">
          claude@litlogs.com
        </a>
      </p>
    </div>
  );
}

export default About;
