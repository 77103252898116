import { getNotes } from "../services/apiService";

const exportNotesToFile = async (isbn, email, title, author, name) => {
  try {
    const notes = await getNotes(isbn, email);

    // Extract the 'notes' and 'date' properties from each note object
    const noteDetails = notes.map((note) => ({
      text: note.notes,
      date: new Date(note.date).toLocaleDateString("en-US"),
    }));

    // Group notes by date
    const groupedNotes = {};
    noteDetails.forEach((note) => {
      if (!groupedNotes[note.date]) {
        groupedNotes[note.date] = [];
      }
      groupedNotes[note.date].push(note.text);
    });

    // Create a text file content with grouped notes
    const fileContent = `${title}\n${author}\n${name}'s notes\n\n--------------------------------------\n\n${Object.entries(
      groupedNotes
    )
      .map(([date, notes]) => `${date}:\n${notes.join("\n\n")}`)
      .join("\n\n")}`;

    // Create a Blob from the text content
    const blob = new Blob([fileContent], { type: "text/plain" });

    // Create a download link and trigger a click event to download the file
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${title} - ${author} (Notes).txt`;
    link.click();
  } catch (error) {
    console.error("Error exporting notes to file:", error);
  }
};

export default exportNotesToFile;
