import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import Header from "./components/Header";
import BookSearch from "./components/BookSearch";
import NoteTakingPage from "./components/NoteTakingPage";
import About from "./components/About";
import Auth from "./components/Auth";
import PasswordReset from "./components/PasswordReset";
import { CssBaseline, Container } from "@mui/material";

function App() {
  const [cookies, setCookie, removeCookie] = useCookies(["AuthToken", "Email"]);
  const authToken = cookies.AuthToken;

  const [selectedOption, setSelectedOption] = useState("Book Search");

  const hasResetToken = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.has("token") && urlParams.get("token").trim() !== "";
  };

  const resetToken = hasResetToken();

  useEffect(() => {
    const storedOption = localStorage.getItem("selectedOption");
    setSelectedOption(storedOption || "Book Search"); // Set to "Book Search" if nothing is stored

    // Handle storage changes
    const handleStorageChange = () => {
      const newStoredOption = localStorage.getItem("selectedOption");
      setSelectedOption(newStoredOption || "Book Search");
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleButtonClick = (option) => {
    setSelectedOption(option);
    localStorage.setItem("selectedOption", option);
  };

  return (
    <div>
      <CssBaseline />
      {authToken ? (
        <div>
          <Header header={"LitLogs"} onButtonClick={handleButtonClick} />
          <main>
            <Container>
              {selectedOption === "Book Search" && <BookSearch />}
              {selectedOption === "Notes" && <NoteTakingPage />}
              {selectedOption === "About" && <About />}
            </Container>
          </main>
        </div>
      ) : resetToken ? (
        <PasswordReset />
      ) : (
        <Auth />
      )}
    </div>
  );
}

export default App;
